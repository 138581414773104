import * as React from "react"
import Layout from '../components/layout'

const IndexPage = (props) => (
    <Layout title="Home" description="">
      <h1>Home</h1>
    </Layout>
)

export default IndexPage

//export const Head = () => <title>Home Page</title>
